import React, {useState} from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnimatedSection, AnimatedButton } from "@components/shared"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { container, container__opinion,
  container__opinions,
  container__opinions__opinion,
  container__opinions__opinion__path,
container__cloud,
container__cloud__button, } from "./styles/students.module.scss"
interface Props {}

interface IReview{
  author: string,
  opinion: string,
  path: string,
  image:{
    sourceUrl: string,
  }
}

const OpinionsTable = [
  {
    name:"Maciek - JS Backend",
    url:"https://academy.localhost-group.com/blog/zapytaj-studenta-akademii-localhost-czesc-1-maciek-js-backend-devops/",
  },
  {
    name:"Grzesiek - JS Backend",
    url:"https://academy.localhost-group.com/blog/zapytaj-studenta-akademii-localhost-czesc-2-grzesiek-js-backend-devops/",
  },
    {
    name:"Karol - Python Backend",
    url:"https://academy.localhost-group.com/blog/zapytaj-studenta-akademii-localhost-czesc-3-karol-python-backend-devops/",
  },
    {
    name:"Jarek - React Frontend",
    url:"https://academy.localhost-group.com/blog/zapytaj-studenta-akademii-localhost-czesc-4-jarek-react-frontend/",
  },
    {
    name:"Adam i Paweł - TypeScript",
    url:"https://academy.localhost-group.com/blog/zapytaj-studenta-akademii-localhost-czesc-5-adam-i-pawe/",
  },
    {
    name:"Adam - React Frontend",
    url:"https://academy.localhost-group.com/blog/zapytaj-studenta-akademii-localhost-czesc-6-adam-react-frontend/",
  },
    {
    name:"Konrad - React Frontend",
    url:"https://academy.localhost-group.com/blog/zapytaj-studenta-akademii-localhost-czesc-7/",
  },
    {
    name:"Maciek - JS Backend",
    url:"https://academy.localhost-group.com/blog/zapytaj-studenta-akademii-localhost-czesc-8-maciek-javascript-backend/",
  },
]

const Students: React.FC<Props> = (): JSX.Element => {
  const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 5000,
          responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    };
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        page(id: "cG9zdDoxNg==") {
          ACF_Reviews {
            reviews {
              reviews {
                author
                opinion
                path
                image 
              }
            }
          }
        }
      }
    }
  `)
  const { reviews } = WP.page.ACF_Reviews.reviews
  return (
    <section className={container}>
      <h2>Nasi studenci o nas</h2>
      <Slider {...settings} className={container__opinions}>
          {reviews.map((el, i) => {
            return (
              <div className={container__opinions__opinion}>
                <p>{el.opinion}</p>
                <img src={el.image} alt="" loading="lazy"/>
                <h6>{el.author}</h6>
                <div className={container__opinions__opinion__path}>
                  <p>{el.path}</p>
                </div>
              </div>
            )
          })}
      </Slider>
    </section>
  )
}

export default Students
