import React from "react"
import { Field } from "formik"
import { modal__content__control } from "./styles/paymentmodal.module.scss"

const StepTwo = ({ formik: { errors, touched } }) => {
  return (
    <>
      <div className={modal__content__control}>
        <label>Twoje imię</label>
        <Field name="first_name" type="text" placeholder={"np. Jan"} />
        {errors.first_name && touched.first_name ? (
          <p>{errors.first_name}</p>
        ) : null}
      </div>
      <div className={modal__content__control}>
        <label>Twoje nazwisko</label>
        <Field name="last_name" type="text" placeholder={"np. Kowalski"} />
        {errors.last_name && touched.last_name ? (
          <p>{errors.last_name}</p>
        ) : null}
      </div>
      <div className={modal__content__control}>
        <label>Twój adres email:</label>
        <Field name="email" type="email" placeholder={"np. jan@kowalski.pl"} />
        {errors.email && touched.email ? <p>{errors.email}</p> : null}
      </div>
    </>
  )
}

export default StepTwo
