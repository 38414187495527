import React, {
  forwardRef,
  Ref,
  useRef,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react"
import { StructuredData } from "@components/layout"

import {
  CustomerCtx,
  useActions,
  CartCtx,
  AppCtx,
  useContextState,
} from "@components/contexted"
import {
  card,
  card__price,
  card__attributes,
  card__divider,
  card__buttons__primary,
} from "./styles/card.module.scss"
import CheckSVG from "./icons/check.svg"
import { AnimatedButton } from "@components/shared"
import Calendar from "../images/calendar.svg"

export const SingleAttribute = (props) => {
  const { text } = props
  return (
    <div>
      <img src={CheckSVG} alt="check"></img>
      <p>{text}</p>
    </div>
  )
}
const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const StandardCard = (props) => {
  const { dispatch } = useContext(CartCtx)
  const { product, openModal, state, setState } = props

  const { attributes, name, regularPrice, image, description } = product
  let cleanDescription = description.replace(/<\/?[^>]+(>|$)/g, "")
  const content = attributes.nodes.filter((el) => el.name === "content")[0]
    .options

  const { setCoupon } = useActions(CartCtx, ["setCoupon"])

  const isPresale =
    product.productCategories.nodes.findIndex(
      (node) => node.slug === "presale"
    ) !== -1
  const addToCart = (product) => {
    setCoupon("")
    setState({
      product_id: product.databaseId,
      name: product.name,
      slug: product.slug,
      price: cleanPrice(product.regularPrice),
      regularPrice: cleanPrice(product.regularPrice),
      regularValue: cleanPrice(product.regularPrice),
      value: cleanPrice(product.regularPrice),
      quantity: 1,
      image: product.image,
      vat: parseFloat(productVat),
      total: cleanPrice(product.regularPrice),
    })
  }
  const productVat = product.attributes.nodes.filter((el) => el.name === 'vat')[0].options[0]
  
  useEffect(() => {
    dispatch({
      type: "resetCart",
    })
    dispatch({
      type: "addToCart",
      payload: state,
    })
  }, [state])
  return (
    <div className={card}>
      <div className={card__price}>
        <text>{cleanPrice(regularPrice)} </text>
        <h5> &nbsp;PLN</h5>
      </div>
      <h4>
        {name} {isPresale && <img src={Calendar} alt="calendar" />}
      </h4>
      <div className={card__attributes}>
        {content.map((el: Object, i: number) => {
          return <SingleAttribute text={el} />
        })}
      </div>
      <AnimatedButton
        className={card__buttons__primary}
        onClick={() => {
          addToCart(product), openModal()
        }}
      >
        <a>
        Biorę wszystkie!

        </a>
      </AnimatedButton>
      <StructuredData>
        {{
          "@context": "http://schema.org/",
          "@type": "Product",
          name: name,
          image: image.sourceUrl,
          description: cleanDescription,
          sku: " ",
          brand: {
            "@type": "Thing",
            name: "Localhost Group",
            url: "https://academy.localhost-group.com",
          },
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: "5",
            reviewCount: "387",
          },
          offers: {
            "@type": "Offer",
            priceCurrency: "PLN",
            price: cleanPrice(product.regularPrice),
            priceValidUntil: "2021-01-25",
            url: "https://academy.localhost-group.com/roadmaps#pricing",
            availability: "http://schema.org/InStock",
            seller: {
              "@type": "Organization",
              name: "Localhost Group",
              logo:
                "http://lha.api.localhost-group.com/wp-content/uploads/2021/02/favicon.png",
            },
          },
        }}
      </StructuredData>
    </div>
  )
}

export default StandardCard
