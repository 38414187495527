// extracted by mini-css-extract-plugin
export var button = "v_l";
export var container = "v_g";
export var container__opinions = "v_bT";
export var container__opinions__opinion = "v_bV";
export var container__opinions__opinion__path = "v_bW";
export var container__opinion = "v_bM";
export var inslide = "v_bX";
export var slidein = "v_bY";
export var outslide = "v_bZ";
export var slideout = "v_b0";