import { AnimatedSection } from "@components/shared"
import React from "react"
import { wrapper } from "./styles/objective.module.scss"
interface Props {}

const Objective: React.FC<Props> = (): JSX.Element => {
  return (
    <section className={wrapper}>
      <div >
        <h2>{content.pl.title}</h2>
        <p>{content.pl.first}</p>
      </div>
    </section>
  )
}

export default Objective

const content = {
  pl: {
    title: "W jakim celu powstała?",
    first:
      "Roadmapa to kompletny i spisany w prostej formie program rozwoju w Akademii, którym prowadzimy naszych studentów. Głównym celem roadmapy jest odzwierciedlanie rynkowej ścieżki rozwoju, dodatkowo rozwijając uniwersalne skille programistyczne (myślenie o rozwiązywaniu problemów) i koderskie (jakość wytwarzanego kodu).",
    secondTitle: "Co było inspiracją do ich opisania?",
    second:
      "Patrzenie na rozwój młodych JS devów, którzy coraz częściej na grupach na facebooku pytają się o kursy, albo mają wątpliwości czy rozwijają się w dobrym kierunku. Te roadmapy dedykuję właśnie tym zagubionym, którzy mają wystarczającą motywację, aby skutecznie wejść do IT i pracować jako programiści.",
  },
  en: {
    title: "W jakim celu powstała?",
    first:
      "Roadmapa to kompletny i spisany w prostej formie program rozwoju w Akademii, którym prowadzę moich studentów. Głównym celem roadmapy jest odzwierciedlanie rynkowej ścieżki rozwoju, dodatkowo rozwijając uniwersalne skille programistyczne (myślenie o rozwiązywaniu problemów) i koderskie (jakość wytwarzanego kodu).",
    secondTitle: "Co było inspiracją do ich opisania?",
    second:
      "Patrzenie na rozwój młodych JS devów, którzy coraz częściej na grupach na facebooku pytają się o kursy, albo mają wątpliwości czy rozwijają się w dobrym kierunku. Te roadmapy dedykuję właśnie tym zagubionym, którzy mają wystarczającą motywację, aby skutecznie wejść do IT i pracować jako programiści.",
  },
}
