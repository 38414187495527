import React from "react"
import {
  wrapper,
  wrapper__image,
  wrapper__content,
  wrapper__content__buttons,
  wrapper__content__buttons__transparent,
  wrapper__content__buttons__primary,
} from "./styles/roadmaphero.module.scss"
import Ilustration from "./images/illustration.svg"
import { AnimatedLink, AnimatedSection } from "@components/shared"

interface Props {}

const RoadMapHero: React.FC<Props> = (): JSX.Element => {
  return (
    <section className={wrapper}>
      <div className={wrapper__content}>
        <h1>{content.pl.title}</h1>
        {content.pl.subtitle}
        <h5>{content.pl.subtitle2}</h5>
        <div className={wrapper__content__buttons}>
          <AnimatedLink
            className={wrapper__content__buttons__primary}
            to="/roadmaps/#pricing"
          >
            {content.pl.button1}
          </AnimatedLink>
        </div>
      </div>
      <img src={Ilustration} alt="" loading={"eager"} className={wrapper__image} />
    </section>
  )
}

export default RoadMapHero

const content = {
  pl: {
    title: "Wytyczymy Ci ścieżkę Twojego rozwoju w IT",
    subtitle: (
      <h3>
        Masz wrażenie, że podczas nauki programowania gubisz sie w ilości dostępnych materiałów? I tak naprawdę nie wiesz, w którą stronę się rozwijać, <br />aby było dobrze?
      </h3>
    ),
    subtitle2:
      "Przydałby się jakiś GPS, który wskaże dobrą ścieżkę rozwoju.",
    button1: "Wybierz swoją ścieżkę rozwoju",
  },
  en: {
    title: "",
    subtitle: "",
    subtitle2: "",
    button1: "",
  },
}

// const content =
//   "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more"
