import { AnimatedSection } from '@components/shared';
import React from 'react';

import Laptop from './icons/laptop.svg';
import Predtiction from './icons/online_prediction.svg';
import Eye from './icons/remove_red_eye.svg';
import Camera from './icons/videocam.svg';
import {
    container,
    container__items,
    container__items__item,
    top,
} from './styles/wings.module.scss';

interface Props {}

const Wings: React.FC<Props> = (): JSX.Element => {
    return (
        <section className={container}>
            <h2>Startujesz od zera? Sprawdź, jak Ci pomożemy</h2>
            <div className={container__items}>
                {content.pl.options.map((el, i) => (
                    <div
                        className={`${container__items__item} ${
                            el.top ? top : ''
                        }`}
                        key={i}>
                        <div>
                            <img src={el.icon} alt="" loading="lazy"/>
                            <p>{el.text}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

const content = {
    pl: {
        title: 'Jak pomagamy rozwinąć skrzydła?',
        options: [
            {
                text:
                    'Dajemy Ci wiele zróżnicowanych i wymagających zadań, abyś przećwiczył komplet najważniejszych umiejętności. Następnie sprawdzamy je, pokazując co i jak można zrobić lepiej oraz jak pisać według dobrych praktyk.',
                icon: Laptop,
            },
            {
                text:
                    'Jeśli napotkasz jakikolwiek problem ze zrozumieniem zadania czy danej partii materiału to robimy wideokonferencje, aby lepiej wyjaśnić temat. Daj nam dać znać o problemie, a zorganizujemy spotkanie, aby wszystko było już jasne.',
                icon: Camera,
                top: true,
            },
            {
                text:
                    'Mentor jest dostępny online około 14 godzin dziennie, także w soboty. Odpoczywamy tylko w niedziele, ale i tak zawsze można napisać na Slacku, jeśli jest jakiś problem ;) Jeśli masz problem z zadaniem, daj znać w wiadomości.',
                icon: Predtiction,
            },
            {
                text:
                    'Wymyślamy projekty do Twojego portfolio i dbamy o to, aby były one unikatowe i niebanalne, wykonane według najlepszych standardów i pokazywały Twoją wartość w oczach przyszłego pracodawcy. Dzięki nim, wyróżnisz się na rynku pracy.',
                icon: Eye,
            },
        ],
    },
    en: {
        title: 'How we help spread wings?',
        options: [
            {
                text:
                    'We give too many tasks to practice the skills from each module, and check them by showing what and how to do better.',
                icon: Laptop,
            },
            {
                text:
                    "We're arranging a videoconference to explain the subject better",
                icon: Camera,
                top: true,
            },
            {
                text:
                    'We are available online about 14 hours a day, often on weekends',
                icon: Predtiction,
            },
            {
                text:
                    'We develop projects for your portfolio and make sure that they are made to the best standards and show your value in the eyes of your future employer.',
                icon: Eye,
            },
        ],
    },
};

export default Wings;
