import {
    AnimatedSection,
    AnimatedButton,
    AnimatedLink,
} from '@components/shared';
import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';

import Slider from './common/Slider';
import LaptopSVG from './icons/laptop.svg';
import PersonSVG from './icons/person.svg';
import {
    container,
    container__opinion,
    container__opinion__button,
    container__opinion__stats,
    container__opinion__stats__single,
    progress,
    progress__fill,
    progress__fill__full,
} from './styles/counter.module.scss';

interface Props {}
interface IElement {
    avatar: {
        sourceUrl: string;
    };
    free: number;
    name: string;
    role: string;
    max: number;
}

const CalculateWidth = (free: number, max: number) => {
    return 100 - (free / max) * 100 + '%';
};

const ProgressBar = (props) => {
    const { free, max } = props;
    let freeCount = free;
    if (freeCount === null) {
        freeCount = 0;
    }
    let setColor = 'white';
    let firstColor = 'white';
    let secondColor = 'white';
    if (100 - (freeCount / max) * 100 <= 50) {
        setColor = 'black';
        secondColor = 'black';
    }
    return (
        <div className={progress}>
            <div
                style={{ width: CalculateWidth(freeCount, max) }}
                className={
                    freeCount === 0 ? progress__fill__full : progress__fill
                }>
                <h5 id="tt" style={{ color: setColor }}>
                    <text style={{ color: firstColor }}>{freeCount}</text>/
                    <text style={{ color: secondColor }}>{max}</text>
                </h5>
            </div>
        </div>
    );
};

const Counter: React.FC<Props> = (): JSX.Element => {
    const date = new Date()
        .toLocaleString('pl-PL', { timeZone: 'Europe/Warsaw' })
        .split(',')[0];
    const { WP } = useStaticQuery(graphql`
        query {
            WP {
                page(id: "cG9zdDoxNg==") {
                    ACF_Counter {
                        counter {
                            avatar {
                                sourceUrl
                            }
                            free
                            max
                            name
                            role
                        }
                    }
                }
            }
        }
    `);
    const { counter } = WP.page.ACF_Counter;
    return (
        <section className={container}>
            <h2>Zapisz się do wybranego mentora, nim będzie za późno!</h2>
            <Slider>
                {counter.map((el: IElement, i: number) => (
                    <div key={i} className={container__opinion}>
                        <h3>{el.name}</h3>
                        <h4>{el.role}</h4>
                        <img
                            src={el.avatar.sourceUrl}
                            loading="lazy"
                            alt="mentor-image"
                        />
                        <p>Wolne miejsca </p>
                        <ProgressBar free={el.free} max={el.max} />
                        {/* <div className={container__opinion__stats}>
                            <div className={container__opinion__stats__single}>
                                <h1>{25 - el.free}</h1><h2>x</h2><img src={PersonSVG} />
                            </div>
                            <div className={container__opinion__stats__single}>
                                <h1>{el.free}</h1><h2>x</h2><img src={LaptopSVG} />
                            </div>
                        </div> */}
                        <AnimatedLink
                            className={container__opinion__button}
                            to={'/#contact'}>
                            Umów się na rozmowę z mentorem
                        </AnimatedLink>
                    </div>
                ))}
            </Slider>
        </section>
    );
};

export default Counter;
