import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
  container,
  container__content,
  container__content__image,
  container__content__wrapper,
  container__content__wrapper__item,
} from "./styles/study.module.scss"
interface Props {}

const Study: React.FC<Props> = (): JSX.Element => {
  return (
    <section className={container}>
      {content.pl.title}
      <div className={container__content}>
        <img 
        src="http://lha.api.localhost-group.com/wp-content/uploads/2021/08/learning.svg"
        alt="learning" 
        // placeholder="blurred"
        loading="eager" 
        className={container__content__image} />
        <div className={container__content__wrapper}>
          {content.pl.content.map((el, i) => (
            <div className={container__content__wrapper__item} key={i}>
              {el.title}
              <p>{el.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Study

const content = {
  pl: {
    title: <h2>Dlaczego powinieneś wybrać naszą akademię, <br />jeśli chcesz nauczyć się programować?</h2>,
    content: [
      {
        title: <h6>Będziesz uczyć się tak, jak Ci wygodnie</h6>,
        text:
          "Masz swoje życie i musisz pracować/uczyć się. Rozumiemy to - tutaj to mentor dopasowuje się do Ciebie i prowadzi indywidualne zajęcia w odpowiadających Ci godzinach.",
      },
      {
        title: (
          <h6>
            Zyskasz pewność, że uczysz się <br />aktualnych rzeczy
          </h6>
        ),
        text:
          "Mentor zawsze stawia na jakość kodu i wytykanie błędów, abyś z każdym commitem pisał kod lepszej jakości.",
      },
      {
        title: (
          <h6>
            Zdobędziesz praktyczne umiejętności, <br />które zapewnią Ci dobry start w branży
          </h6>
        ),
        text:
          "Nasze materiały tworzymy tak, aby mierzyć się z problemami, z którymi będziesz spotkasz się w pracy - to nie są “tutorialowe” zadania. Rekrutacja przy naszych ćwiczeniach to pikuś ;)",
      },
    ],
  },
  en: {
    title: "",
    content: [
      {
        title: "",
        text: "",
      },
    ],
  },
}
