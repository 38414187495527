import React, { Children, useCallback, useMemo, useRef, useState } from "react"
import { wrapper } from "./slider.module.scss"
import Left from "../icons/left.svg"
import Right from "../icons/right.svg"
import { inslide, outslide } from "../styles/students.module.scss"
interface Props {}

const Slider: React.FC<Props> = ({ children }): JSX.Element => {
  const [selected, setSelected] = useState(0)
  const ref = useRef({ className: "" })
  const childrenTable = useMemo(() => Children.toArray(children), [])

  const prevChild = useCallback(() => {
    if (ref.current) {
      ref.current.className = `${inslide}`
    }
    if (selected === 0) {
      setSelected(childrenTable.length - 1)
    } else {
      setSelected((prev) => prev - 1)
    }
  }, [selected])

  const nextChild = useCallback(() => {
    if (ref.current) {
      ref.current.className = `${outslide}`
    }

    setSelected((prev) => (prev + 1) % childrenTable.length)
  }, [selected])

  return (
    <div className={wrapper}>
      <button onClick={prevChild} aria-label="Prev mentor">
        <img src={Left} alt="" />
      </button>
      {React.cloneElement(childrenTable[selected], {
        className: `${childrenTable[selected].props.className} ${ref.current.className}`,
      })}
      <button onClick={nextChild} aria-label="Next mentor">
        <img src={Right} alt="" />
      </button>
    </div>
  )
}

export default Slider
