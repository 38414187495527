import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';

import Play from './icons/play.svg';
import {
    container,
    container__season,
    container__season__video,
    container__season__video__image,
    container__season__video__image__overlay,
    container__season__video__content,
} from './styles/records.module.scss';

function truncateString(str, num) {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
}

const Records: React.FC = (): JSX.Element => {
    const { WP } = useStaticQuery(graphql`
        {
            WP {
                page(id: "cG9zdDoyMzQw") {
                    ACF_Records {
                        seasons {
                            year
                            record {
                                url
                                title
                                description
                                date
                                image {
                                    sourceUrl
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const { seasons } = WP.page.ACF_Records;

    const createSlug = (title) => {
        let _title = title.toLowerCase();
        let slug = _title
            .replace(/\./g, '')
            .replace(/\,/g, '')
            .replace(/\?/g, '');
        return slug.replace(/ /g, '-');
    };

    return (
        <div className={container}>
            {seasons.map((season) => (
                <div className={container__season}>
                    <h3>{season.year}</h3>
                    {season.record.map((el, i) => {
                        season.record.sort((a, b) => {
                            let dateA = new Date(a.date);
                            let dateB = new Date(b.date);
                            return dateB - dateA;
                        });

                        return (
                            <div
                                className={container__season__video}
                                id={createSlug(el.title)}>
                                <div
                                    className={container__season__video__image}>
                                    <img
                                        src={el.image.sourceUrl}
                                        loading="lazy"
                                    />
                                    <div
                                        className={
                                            container__season__video__image__overlay
                                        }>
                                        <a href={el.url} target="_blank">
                                            <img src={Play} loading="lazy" />
                                            <small>
                                                Kliknij, aby odtworzyć nagranie
                                            </small>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    className={
                                        container__season__video__content
                                    }>
                                    <time>{el.date}</time>
                                    <h4>{el.title}</h4>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: el.description,
                                        }}></p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};

export default Records;
