import React from "react"

import {
  AnimatedButton,
  AnimatedLink,
  AnimatedSection,
} from "@components/shared"
import { container, container__button } from "./styles/mission.module.scss"
interface Props {}

const Mission: React.FC<Props> = (): JSX.Element => {
  return (
    <section className={container}>
      <h2>{content.pl.title}</h2>
      <AnimatedLink className={container__button} to="/#pricing">
        {content.pl.button}
      </AnimatedLink>
    </section>
  )
}

export default Mission

const content = {
  pl: {
    title:
      "Dzięki nam staniesz się juniorem gotowym do zdobywania świata IT. Prosto, szybko, całkowicie zdalnie.",
    button: "Sprawdź naszą ofertę",
  },
  en: {
    title: "",
    subtitle: "",
  },
}
