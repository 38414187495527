import { AnimatedSection } from "@components/shared"
import React from "react"
import {
  wrapper,
  wrapper__items,
  wrapper__items__item,
} from "./styles/benefits.module.scss"
interface Props {}

const Benefits: React.FC<Props> = (): JSX.Element => {
  return (
    <section className={wrapper}>
      <h2>{content.pl.title}</h2>
      <div className={wrapper__items}>
        {content.pl.options.map((el, i) => (
          <div className={wrapper__items__item} key={i}>
            <h5>{el.title}</h5>
            <p>{el.text}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Benefits

const content = {
  pl: {
    title: "Co otrzymasz w pakiecie?",
    options: [
      {
        title: "Plan Twojego rozwoju",
        text:
          "Rozrysowany w przyjaznej formie graficznej plan Twojego rozwoju. Jest po to, abyś wiedział dokąd zmierzasz i ile jeszcze Ci zostało do końca danego etapu lub do uzyskania pierwszej pracy.",
      },
      {
        title: "Dedykowane materiały",
        text:
          "Komplet materiałów, sprawdzonych przez mentora i uczniów - do każdego etapu. Pomogą Ci zdobyć wartościową wiedzę.",
      },
      {
        title: "Praktyczne zadania",
        text:
          "40+ złożonych zadań, pozwalających na praktyczne ćwiczenie na bieżąco zdobywanej wiedzy - w końcu programiści uczą się przez pisanie kodu, a nie przez oglądanie kursów.",
      },
      {
        title: "Rozwiązania do zadań wg dobrych praktyk",
        text:
          "Ogromna baza gotowego kodu wg dobrych praktyk, prosto od 2 najlepszych studentów. Będziesz mógł odnieść swoje rozwiązanie do tego z Akademii. Każde zadanie zawiera testy i opisy dlaczego ten kod wygląda właśnie tak, a nie inaczej.",
      },
    ],
  },
  en: {
    title: "",
    options: [],
  },
}
