import React from "react"
import { AnimatedSection, AnimatedLink } from "@components/shared"
import {
  container,
  container__button,
  container__grid,
  container__grid__item,
} from "./styles/pillars.module.scss"
interface Props {}

const Pillars: React.FC<Props> = (): JSX.Element => {
  return (
    <section className={container}>
      <h2>Nie jesteśmy dla Ciebie, jeśli boisz się wyzwań</h2>
      <div className={container__grid}>
        {content.pl.options.map((e, i) => (
          <div className={container__grid__item} key={i}>
            <h6>{e.title}</h6>
            <p>{e.text}</p>
          </div>
        ))}
      </div>
      <AnimatedLink to={"/#pricing"}className={container__button}>Sprawdź naszą ofertę</AnimatedLink>
    </section>
  )
}

export default Pillars
const content = {
  pl: {
    title: "4 filary Akademii",
    options: [
      {
        title: "Stawiamy na jakość",
        text:
          "Każdemu studentowi w Akademii zapewniamy dopasowany do jego potrzeb program rozwoju. Uczysz się praktycznych umiejętności w idealnym dla siebie tempie.",
      },
      {
        title: "Nie owijamy w bawełnę",
        text:
          "Programowanie jest trudne i nie jest dla każdego. Jeśli po dwóch miesiącach stwierdzimy, że nie nadajesz się na programistę - zwrócimy Ci 50% poniesionych kosztów.",
      },
      {
        title: "To nie będzie łatwa przeprawa",
        text:
          "Tutaj uczysz się samodzielnie lub w parach, z materiałów i zadań, które układają dla Ciebie mentorzy, którzy są po to, by czuwać nad Twoim rozwojem, przydzielać i oceniać zadania oraz pomagać Ci w miejscach, w których utkniesz.",
      },
      {
        title: "Elastyczna forma współpracy",
        text:
          "Miesięczne czesne zależy w 100% od tego, ile czasu tygodniowo możesz poświęcić na naukę. Jeśli Twoje życie spowoduje, że musisz przerwać lub zrobić pauzę na miesiąc czy dwa - wystarczy jasna informacja i czekamy aż do nas wrócisz.",
      },
    ],
  },
  en: {
    title: "4 pillars of Academy",
    options: [
      {
        title: "We focus on quality",
        text:
          "We provide each trainee programmer at the Academy with a tailored development program. You learn practical skills at an ideal pace for yourself",
      },
      {
        title: "We don't beat around the bush",
        text:
          " Programming is difficult and it is not for everyone if after a month or 2 we find that you are not a suitable programmer - we will pay you back 50% of the money",
      },
      {
        title: "It will not be an easy crossing ",
        text:
          "You learn on your own or in pairs from the materials and tasks that the mentors are arranging for you, and they are there to supervise your development, assign and evaluate tasks and help you in places where you get stuck",
      },
      {
        title: "The amount of tuition tailored to you",
        text:
          "Monthly tuition fees depend on how much time you can devote to study each week (7h/15h/30+h). But in our experience, it is better for you if you spend more than t1 hour a day doing it",
      },
    ],
  },
}
