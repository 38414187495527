import React from "react"
import { wrapper } from "./styles/pajacyk.module.scss"
import Image from "./images/pajacyk.png"
const Pajacyk = () => {
  return (
    <div className={wrapper}>
      <img src={Image} alt="pajacyk" />
      <p>
        Z każdej zakupionej ścieżki rozwoju, przekażemy 7 złotych na Pajacyka!
      </p>
    </div>
  )
}

export default Pajacyk
