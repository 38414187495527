import { AnimatedButton } from "@components/shared";
import { navigate } from "gatsby";
import React from "react";



import { container, container__image, container__content, container__content__buttons, container__content__buttons__primary, container__content__buttons__transparent } from "./styles/hero.module.scss";


interface Props {}

const Hero: React.FC<Props> = (): JSX.Element => {
  return (
    <section className={container}>
      <div className={container__content}>
        <h1>{content.pl.title}</h1>
        {content.pl.subtitle}
        <div className={container__content__buttons}>
          {/* <AnimatedButton
            className={container__content__buttons__transparent}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/#about")}
          >
            {content.pl.button1}
          </AnimatedButton> */}
          <AnimatedButton
            className={container__content__buttons__primary}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/#pricing")}
          >
            {content.pl.button2}
          </AnimatedButton>
        </div>
      </div>
      <img 
      src="http://lha.api.localhost-group.com/wp-content/uploads/2021/08/learning.svg"
      alt="hero" 
      // placeholder="blurred"
      loading={"lazy"}
      className={container__image} />
    </section>
  )
}

export default Hero

const content = {
    pl: {
        title:
            // "Rozwijaj swoje umiejętności pod okiem programistów z wieloletnim doświadczeniem.",
            'Z nami staniesz się programistą, o którego zagraniczni pracodawcy będą się zabijać',
        subtitle: (
            // <h3>Nauczymy Cię programowania zdalnie w odpowiednim dla Ciebie tempie. Z nami staniesz się pełnoprawnym programistą najszybciej jak to możliwe.</h3>,
            <h3>
                Zdalnie nauczymy Cię kodować jak mistrz i myśleć jak
                programista. Zrobimy to w dopasowanym dla Ciebie tempie, aż
                będziesz gotowa/-ów zdobywać rynek
            </h3>
        ),
        button1: 'O nas',
        button2: 'Chcę dołączyć do Akademii',
        // button2: "Sprawdź naszą ofertę",
    },
    en: {
        title: '',
        subtitle: '',
        button1: '',
        button2: '',
    },
};