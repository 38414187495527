import React from "react"

import {
  container,
  container__header,
  container__content,
  container__content__image,
  container__content__image__charity,
  container__content__paths,
  container__content__paths__single,
  container__content__paths__single__content,
  container__content__paths__single__content__option,
  container__content__paths__single__content__option__headers,
  container__content__paths__single__content__option__button,
} from "./styles/paths.module.scss"
import DirectionSVG from "./images/direction.svg"
import PajacykSVG from "./icons/pajacyk.svg"
import { AnimatedSection, AnimatedButton } from "@components/shared"

interface Props {
  bold: boolean
  text: string
}

const Paths: React.FC<Props> = ({ bold = false, text }): JSX.Element => {
  return (
    <section className={container}>
      <div id="roadmaps" />
      <div className={container__header}>
        <h2 style={{ fontWeight: 700 }}>
          {content.pl.title}
        </h2>
      </div>
      <div className={container__content}>
        <div className={container__content__image}>
          <img src={DirectionSVG} alt="direction"></img>
          {/* <div className={container__content__image__charity}>
            <img src={PajacykSVG}></img>
            <h5>{content.pl.subtitle}</h5>
          </div> */}
        </div>
        <div className={container__content__paths}>
          <div className={container__content__paths__single}>
            <div className={container__content__paths__single__content}>
              {content.pl.paths.map((el, i) => {
                return (
                  <div
                    className={
                      container__content__paths__single__content__option
                    }
                    key={i}
                    style={{
                      width: i === 4 ? "620px" : "294px",
                      height: i === 4 ? "163px" : "196px",
                      marginBottom: i === 4 ? "0px" : "32px",
                    }}
                  >
                    <div
                      className={
                        container__content__paths__single__content__option__headers
                      }
                    >
                      {el.label}
                      {el.duration}
                    </div>
                    <AnimatedButton
                      className={
                        container__content__paths__single__content__option__button
                      }
                    >
                      <a target="_blank" href={el.url}>
                        {content.pl.buttonLabel}
                      </a>
                    </AnimatedButton>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Paths

const content = {
  pl: {
    title: "Jak rozwiniesz się dzięki roadmapie?",
    subtitle: "Z każdej zakupionej roadmapy, przekażemy 7 złotych na Pajacyka!",
    paths: [
      {
        label: <h4>Javascript and Typescript Fundamentals</h4>,
        duration: <h5>4-6 miesięcy</h5>,
        url:
          "https://lha.api.localhost-group.com/wp-content/uploads/2021/03/flowchartjsts-1.pdf",
      },
      {
        label: (
          <h4>
            React Typescript
            <br /> Developer
          </h4>
        ),
        duration: <h5>4-5 miesięcy</h5>,
        url:
          "https://lha.api.localhost-group.com/wp-content/uploads/2021/05/flowchartreact_new.pdf",
      },
      {
        label: <h4>Typescript Backend Developer</h4>,
        duration: <h5>5-7 miesięcy</h5>,
        url:
          "https://lha.api.localhost-group.com/wp-content/uploads/2021/05/flowchartbackendnowy_new.pdf",
      },
      {
        label: <h4>Portfolio Builder</h4>,
        duration: <h5>3-4 miesiące</h5>,
        url:
          "https://lha.api.localhost-group.com/wp-content/uploads/2021/03/flowchartportfolio-1.pdf",
      },
    ],
    buttonLabel: "Zobacz roadmapę",
  },
  en: {
    title: "",
    subtitle: "",
    paths: [],
    buttonLabel: "",
  },
}