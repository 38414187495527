import React from "react"
import {
  container,
  container__content,
  container__content__wrapper,
  container__content__wrapper__item,
} from "./styles/find.module.scss"

import Image from "./images/trees.svg"

interface Props {}
const Find: React.FC<Props> = (): JSX.Element => {
  return (
    <section className={container}>
      <div id="about" style={{ position: "absolute", top: "-80px" }} />
      {content.pl.title}
      <div className={container__content}>
        <img 
        src={Image}
        alt="walking"       
        loading={"lazy"}
        />
        <div className={container__content__wrapper}>
          {content.pl.options.map((el, i) => (
            <div className={container__content__wrapper__item} key={i}>
              {el}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Find

const content = {
  pl: {
    title: (
      <h2>
        W naszej Akademii szybko i łatwo nauczysz się <br />
        programować dzięki stałej pomocy mentorów. 
      </h2>
    ),
    options: [
      <p>
        Wielokrotnie podchodziłeś do nauki programowania, ale szybko traciłeś zapał?{" "}
        <br />
      </p>,
      <p>
        Zbyt duża ilośc niezweryfikowanych materiałów o programowaniu Cię przytłacza?
      </p>,
      <p>
        Nie wiesz, jak dobrze zacząć i pokierować swoją nauką by znaleźć pracę w IT?
      </p>,
    ],
  },
  en: {
    title:
      "In our Academy you will find current learning materials, time and help from mentors and support in conquering the market.",
    options: [
      <p>
        Have you learned programming on bootcamps or alone from the materials
        available on the Internet?
      </p>,
      <p>
        Are you looking for an internship, portfolio projects and opportunities
        to practice your own software writing skills under the guidance of
        experienced colleagues?
      </p>,
      <p>
        Don't you know which way to go further, so you don’t waste valuable time
        and find a job in IT as soon as possible?
      </p>,
    ],
  },
}
