import { withHtml } from '@components/logic';
import { Link, useStaticQuery, graphql, navigate } from 'gatsby';
import _ from 'lodash';
import React, { useState } from 'react';

import { findInString } from './logic';
import {
    wrapper,
    wrapper__content,
    wrapper__links,
    wrapper__links__link,
} from './styles/searchrecords.module.scss';

const H6 = withHtml('h6');

interface SearchProps {
    title: string;
    url: string;
    type: string;
    description: string;
    image: string;
    date: string;
    phrase: string;
}

const SearchListElem: React.FC<SearchProps> = ({
    title,
    url,
    type,
    date,
    image,
    description,
    phrase,
}): JSX.Element => {
    const createSlug = (title) => {
        let _title = title.toLowerCase();
        let slug = _title
            .replace(/\./g, '')
            .replace(/\,/g, '')
            .replace(/\?/g, '');
        return slug.replace(/ /g, '-');
    };
    const id = createSlug(title);
    const yOffset = -100;
    const element = document.getElementById(id);
    const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    return (
        <a
            onClick={() => window.scrollTo({ top: y, behavior: 'smooth' })}
            className={wrapper__links__link}>
            <H6>{_.truncate(title, { length: 80 })}</H6>
            <img src={image} alt="product" />
        </a>
    );
};

const SearchRecords: React.FC = (): JSX.Element => {
    const [state, setState] = useState({ phrase: '', filtered: [] });

    const { WP } = useStaticQuery(graphql`
        query {
            WP {
                page(id: "cG9zdDoyMzQw") {
                    ACF_Records {
                        seasons {
                            year
                            record {
                                url
                                title
                                description
                                date
                                image {
                                    sourceUrl
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const { seasons } = WP.page.ACF_Records;
    let records = [];
    seasons.forEach((season) => season.record.map((el) => records.push(el)));

    const _records = records.map((el) => {
        return {
            title: el.title,
            date: el.date,
            type: 'record',
            description: el.description,
            image: el.image.sourceUrl,
            url: el.url,
        };
    });

    const all = [..._records];
    const setPhrase = (ev) => {
        let phrase = ev.target.value;
        let _filtered = [];
        let random = null;
        if (phrase === 'random()') {
            random = all[Math.floor(Math.random() * all.length)];
            _filtered = [random];
        } else if (phrase.length >= 2) {
            _filtered = all.filter((el) => {
                return (
                    [findInString(el.title, phrase)].includes(true) ||
                    [findInString(el.description, phrase)].includes(true)
                );
            });
        }

        setState({
            phrase: ev.target.value,
            filtered: _filtered,
        });
    };
    return (
        <div className={wrapper}>
            <input
                type="text"
                value={state.phrase}
                onChange={(ev) => {
                    setState({
                        phrase: ev.target.value,
                    });
                    setPhrase(ev);
                }}
                placeholder={'Napisz czego szukasz lub wpisz random()'}
            />
            <div className={wrapper__links}>
                {state.phrase &&
                    state.filtered.map((el, k) => {
                        return (
                            <SearchListElem
                                {...el}
                                phrase={state.phrase}
                                key={k}
                                i={k}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default SearchRecords;

// let phraseIndex = 0;
// let lowerDesc = description.toLowerCase();
// let clean = lowerDesc.replace(/<\/?[^>]+(>|$)/g, '');
// let lowerPhrase = phrase.toLowerCase()
// let phraseInDesc = clean.includes(lowerPhrase)

// if(lowerPhrase){
//     phraseIndex = clean.indexOf(lowerPhrase) ||
//     clean.indexOf(lowerPhrase + /.|,|" "|-/) ||
//     clean.indexOf(/.|,|" "|-/ + lowerPhrase) ||
//     clean.indexOf(/.|,|" "|-/ + lowerPhrase + /.|,|" "|-/)
// }
// let fragment = "";
// if(phraseIndex != -1){
//     fragment = clean.substring(phraseIndex, phraseIndex + 60 )
// }
