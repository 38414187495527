import {
    CustomerCtx,
    useActions,
    CartCtx,
    AppCtx,
    useContextState,
} from '@components/contexted';
import { StructuredData } from '@components/layout';
import { AnimatedButton, AnimatedSection } from '@components/shared';
import { deepEqual } from 'node:assert';
import React, {
    forwardRef,
    Ref,
    useRef,
    useCallback,
    useState,
    useContext,
    useEffect,
} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import CheckSVG from './icons/check.svg';
import MinusSVG from './icons/minus.svg';
import PlusSVG from './icons/plus.svg';
import {
    container,
    container__levels,
    container__levels__labels,
    container__mlevels,
    container__items,
    container__items__single,
    container__items__single__content,
    container__items__single__content__quantity,
    container__items__single__content__quantity__rrso,
    container__items__single__content__quantity__controls,
    container__items__single__content__attributes,
    container__items__single__content__buttons__primary,
    container__items__single__content__buttons__transparent,
    choosen,
    container__roadmap,
    container__roadmap__single,
} from './styles/offer.module.scss';

const cleanPrice = (price) => {
    return parseFloat(price.replace(/[$,]+/g, ''));
};

interface Props {}

interface LevelProps {
    levels: any;
    level: any;
    setLevel: any;
}

const SingleAttribute = (props) => {
    const { text } = props;
    return (
        <div>
            <img src={CheckSVG} alt="check"></img>
            <p>{text}</p>
        </div>
    );
};

const SingleCard = (props) => {
    const { dispatch } = useContext(CartCtx);
    const {
        product,
        state,
        setState,
        isSelected,
        onModalOpen,
        selected,
        setSelected,
        index,
        minHeight,
        instalment,
        quantity,
        defaultSelected,
        monthly,
    } = props;
    const {
        name,
        description,
        regularPrice,
        attributes,
        id,
        image,
        shortDescription,
    } = product;
    const { coupon } = useContextState(CartCtx, ['coupon']);
    const { setCoupon, changeProductQty } = useActions(CartCtx, [
        'setCoupon',
        'changeProductQty',
    ]);
    let cleanDescription = description.replace(/<\/?[^>]+(>|$)/g, '');
    let cleanShortDescription = shortDescription.replace(/<\/?[^>]+(>|$)/g, '');
    const productAttributes = attributes.nodes[0].options;

    const [single, setSingle] = useState({
        product_id: product.databaseId,
        name: product.name,
        slug: product.slug,
        price: cleanPrice(product.regularPrice),
        regularPrice: cleanPrice(product.regularPrice),
        regularValue: cleanPrice(product.regularPrice),
        value: cleanPrice(product.regularPrice),
        quantity: 1,
        image: product.image,
        total: cleanPrice(product.regularPrice),
        vat: 0.23,
    });
    const changeQty = (val) => {
        const _number = Number(val);
        if (_number <= 0 || Number.isNaN(_number)) {
            return false;
        } else {
            setSingle({
                ...single,
                quantity: _number,
                value: _number * single.price,
                regularValue: _number * single.regularPrice,
            });
        }
    };

    const addToCart = () => {
        dispatch({
            type: 'resetCart',
        }),
            dispatch({
                type: 'addToCart',
                payload: single,
            });
    };

    useEffect(() => {
        if (!isSelected) {
            changeQty(1);
        }
    }, [isSelected]);


    return (
        <div
            className={`${container__items__single} ${isSelected || defaultSelected === index ? choosen : ''
                }`}
            onClick={() => {
                setSelected(index);
            }}>
            <div className={container__items__single__content}>
                <h4>{name}</h4>
                <h5>{cleanDescription}</h5>
                {cleanShortDescription ? (
                    <h5>{cleanShortDescription}</h5>
                ) : null}
                {monthly ? (
                <text>
                    {cleanPrice(regularPrice)} <small>PLN / miesiąc</small>
                </text>
                ):(
                <text>
                    {cleanPrice(regularPrice)} <small>PLN</small>
                </text>
                )}

                {quantity ? (
                <div className={container__items__single__content__quantity}>
                    <h5>Wybierz liczbę miesięcy:</h5>
                    <div
                        className={
                            container__items__single__content__quantity__controls
                        }>
                        <button
                            onClick={() => changeQty(single.quantity - 1)}>
                            <img src={MinusSVG} alt="Subtract value" />
                        </button>
                        <h5>{single.quantity}</h5>
                        <button
                            onClick={() => changeQty(single.quantity + 1)}>
                            <img src={PlusSVG} alt="Add value" />
                        </button>
                    </div>
                    {instalment ? (
                        <div
                        className={
                            container__items__single__content__quantity__rrso
                        }>
                        <small>
                            W przypadku zakupów na raty, RSO wynosi 17%
                        </small>
                        <small>
                            Koszt miesięczny{' '}
                            {Math.round((single.value * 1.17) / 24)} PLN
                        </small>
                        <small>przy 24 ratach</small>
                    </div>
                    ):(
                        null
                    )}
                    
                </div>
                ):(
                    null
                )}
                <div style={{minHeight:minHeight}}className={container__items__single__content__attributes}>
                    {productAttributes.map((el, i) => {
                        return <SingleAttribute text={el} key={i} />;
                    })}
                </div>
                <AnimatedButton
                    style={{ fontWeight: 400 }}
                    onClick={() => {
                        console.log(single);
                        addToCart(), onModalOpen();
                    }}
                    className={
                        container__items__single__content__buttons__primary
                    }>
                    <a>{'Opłać czesne'}</a>
                </AnimatedButton>
            </div>
            <StructuredData>
                {{
                    '@context': 'http://schema.org/',
                    '@type': 'Product',
                    name: 'Mentoring ' + name,
                    image: image.sourceUrl,
                    description: cleanDescription,
                    sku: ' ',
                    brand: {
                        '@type': 'Thing',
                        name: 'Localhost Group',
                        url: 'https://academy.localhost-group.com',
                    },
                    aggregateRating: {
                        '@type': 'AggregateRating',
                        ratingValue: '5',
                        reviewCount: '387',
                    },
                    offers: {
                        '@type': 'Offer',
                        priceCurrency: 'PLN',
                        price: cleanPrice(product.regularPrice),
                        priceValidUntil: '2021-01-25',
                        url: 'https://academy.localhost-group.com/#pricing',
                        availability: 'http://schema.org/InStock',
                        seller: {
                            '@type': 'Organization',
                            name: 'Localhost Group',
                            logo: 'http://lha.api.localhost-group.com/wp-content/uploads/2021/02/favicon.png',
                        },
                    },
                }}
            </StructuredData>
        </div>
    );
};

const LevelSelectorMobile: React.FC<LevelProps> = (props): JSX.Element => {
    const { levels, level, setLevel } = props;
    return (
        <div className={container__mlevels}>
            <select onChange={(ev) => setLevel(ev.target.value)}>
                {levels.map((el, i) => (
                    <option value={el.from}>{el.label}</option>
                ))}
            </select>
        </div>
    );
};

const LevelSlider: React.FC<LevelProps> = (props): JSX.Element => {
    const { levels, level, setLevel } = props;
    const colorSetter = (level, from, to) => {
        if (level >= from + 1 && level <= to) {
            return '#1301a3';
        } else {
            return 'black';
        }
    };
    return (
        <div className={container__levels}>
            <input
                type="range"
                step="1"
                min="1"
                max="100"
                onChange={(ev) => setLevel(ev.target.value)}
                value={level}></input>
            <div className={container__levels__labels}>
                {levels.map((el, i) => (
                    <h5 style={{ color: colorSetter(level, el.from, el.to) }}>
                        {el.label}
                    </h5>
                ))}
            </div>
        </div>
    );
};

const Offer: React.ForwardRefRenderFunction<Ref<HTMLElement>> = (
    props,
    ref,
): JSX.Element => {
    const settings = {
        //   dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    autoplay: true,
                    speed: 3000,
                    autoplaySpeed: 8000,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 1366,
                settings: {
                    autoplay: true,
                    speed: 3000,
                    autoplaySpeed: 8000,
                    infinite: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };
    const { products } = props;
    const { dispatch } = useContext(CartCtx);
    const [selected, setSelected] = useState(null);
    const [level, setLevel] = useState(0);
    const [state, setState] = useState({
        value: null,
        regularValue: null,
        quantity: 1,
        product_id: null,
        variation_id: null,
        variant: null,
        sale: false,
        price: '',
        regularPrice: '',
        unit: 'PLN',
        name: '',
        slug: '',
        image: null,
    });
    useEffect(() => {
        dispatch({
            type: 'resetCart',
        });
        dispatch({
            type: 'addToCart',
            payload: state,
        });
    }, [state.product_id]);
    // const levels = [
    //         {
    //             from: 0,
    //             to: 20,
    //             label: "Dopiero zaczynam (Zielony)"
    //         },
    //         {
    //             from: 20,
    //             to: 40,
    //             label: "Już coś umiem (Newbie)",
    //         },
    //         {
    //             from: 40,
    //             to: 60,
    //             label: "Myślę, że jestem gotowy na rynek (PreJunior)",
    //         },
    //         {
    //             from: 60,
    //             to: 80,
    //             label: "Pracuję jako Junior",
    //         },
    //         {
    //             from: 80,
    //             to: 100,
    //             label: "Pracuję jako Regular"
    //         },
    //     ]
    // rozwój długofalowy
    const singleProducts = products.filter(
        (el) => el.productCategories.nodes[0].slug === 'czesne',
    );

    // rozwój krótkofalowy
    const shortProducts = products.filter(
        (el) => el.productCategories.nodes[0].slug === 'short',
    );
    return (
        <section className={container}>
            <div id="pricing" style={{ position: 'absolute', top: '-150px' }} />
            <h2>Już dziś zrób pierwszy krok w stronę programowania!</h2>
            <h2>Rozwój długofalowy</h2>

            {/* <LevelSlider levels={levels} level={level} setLevel={setLevel} />
            <LevelSelectorMobile levels={levels} level={level} setLevel={setLevel} /> */}
            <Slider {...settings} className={container__items}>
                {singleProducts.map((product: Object, i: number) => {
                    return (
                        <SingleCard
                            minHeight="535px"
                            product={product}
                            index={i}
                            key={i}
                            isSelected={i === selected}
                            instalment={true}
                            quantity={true}
                            monthly={true}
                            onModalOpen={() => ref.current.toggle()}
                            selected={selected}
                            setSelected={setSelected}
                            defaultSelected={2}
                        />
                    );
                })}
            </Slider>

            <h2 style={{ marginTop: '50px' }}>Rozwój krótkofalowy</h2>
            <Slider {...settings} className={container__items}>
                {shortProducts.map((product: Object, i: number) => {
                    return (
                        <SingleCard
                            minHeight="300px"
                            product={product}
                            index={i+3}
                            key={i+3}
                            isSelected={i+3 === selected}
                            instalment={false}
                            quantity={false}
                            monthly={false}
                            onModalOpen={() => ref.current.toggle()}
                            selected={selected}
                            setSelected={setSelected}
                            defaultSelected={5}
                        />
                    );
                })}
            </Slider>
        </section>
    );
};

export default forwardRef(Offer);
