import { AnimatedSection } from '@components/shared';
import React from 'react';

import useMediaQuery from './common/useMediaQuery';
import Question from './components/Question';
import { wrapper } from './styles/faq.module.scss';

interface Props {
    questions: any;
}

const Faq: React.FC<Props> = ({ questions }): JSX.Element => {
    return (
        <section className={wrapper}>
            <h2>FAQ</h2>
            {questions.map((el, i) => (
                <Question el={el} key={i} />
            ))}
        </section>
    );
};

export default Faq;
