import React from "react"
import { wrapper, wrapper__content } from "./styles/why.module.scss"
import { AnimatedSection } from "@components/shared"
interface Props {}

const Why: React.FC<Props> = (): JSX.Element => {
  return (
    <section className={wrapper}>
      <img src="http://lha.api.localhost-group.com/wp-content/uploads/2021/08/woman.svg" alt="woman-talking" />
      <div className={wrapper__content}>
        <h2>Co było inspiracją do ich opisania?</h2>
        <p>{content}</p>
      </div>
    </section>
  )
}

export default Why

const content =
  "Patrzenie na rozwój młodych JS devów, którzy coraz częściej na grupach na facebooku pytają się o kursy, albo mają wątpliwości czy rozwijają się w dobrym kierunku. Te roadmapy dedykuję właśnie tym zagubionym, którzy mają wystarczającą motywację, aby skutecznie wejść do IT i pracować jako programiści."
