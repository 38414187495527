import { AnimatedSection, AnimatedButton } from '@components/shared';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';



import { container,
container__slider,
container__slider__slide,
} from "./styles/partners.module.scss";


interface Props { }

const Slide = (props) => {
    const { img, url } = props
    return(
        <div className={container__slider__slide}>
            <a target="_blank" href={url}>
            <img src={img.sourceUrl} />

            </a>
        </div>
    )
}

const Partners: React.FC<Props> = (): JSX.Element => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
      const { WP } = useStaticQuery(graphql`
          query {
              WP {
                  page(id: "cG9zdDoxNg==") {
                      ACF_Partners {
                          items {
                              url
                              img {
                                  sourceUrl
                              }
                          }
                      }
                  }
              }
          }
      `);

    const { items } = WP.page.ACF_Partners

    console.log('i', items)

    return (
        <section className={container}>
            <h2>Software house-y i firmy technologiczne, które zatrudniają naszych studentów</h2>
                        <Slider {...settings} className={container__slider}>
                {items.map((el, i) => {
                    return (
                        <Slide {...el} />
                    )
                })}
            </Slider>
        </section>
    )
}

export default Partners