import React from 'react';

import BlockchainSVG from './icons/blockchain.svg';
import PythonSVG from './icons/python.svg';
import ReactSVG from './icons/react.svg';
import TypescriptSVG from './icons/typescript.svg';
import DirectionSVG from './images/direction.svg';
import {
    container,
    container__header,
    container__content,
    container__content__image,
    container__content__paths,
    container__content__paths__single,
} from './styles/paths.module.scss';

interface Props {
    bold: boolean;
    text: string;
}

const Paths: React.FC<Props> = ({ bold = false, text }): JSX.Element => {
    return (
        <section className={container}>
            <div
                id="roadmaps"
                style={{ position: 'absolute', top: '-100px' }}
            />
            <div className={container__header}>
                <h2 style={{ fontWeight: 700 }}>{content.pl.title}</h2>
            </div>
            <div className={container__content}>
                <div className={container__content__image}>
                    <img
                        src={DirectionSVG}
                        loading="lazy"
                        alt="direction"></img>
                </div>
                <div className={container__content__paths}>
                    {content.pl.paths.map((el, i) => {
                        return (
                            <div className={container__content__paths__single}>
                                <h4>{el.title}</h4>
                                <img
                                    src={el.icon}
                                    loading="lazy"
                                    alt={el.alt}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};
export default Paths;

const content = {
    pl: {
        title: 'Zobacz na jakich ścieżkach trenujemy',
        subtitle:
            'Z każdej zakupionej roadmapy, przekażemy 7 złotych na Pajacyka!',
        paths: [
            {
                title: 'React Typescript Developer',
                icon: ReactSVG,
                alt: 'React logo',
            },
            {
                title: 'Backend Typescript Developer',
                icon: TypescriptSVG,
                alt: 'Typescript logo',
            },
            {
                title: 'Python Backend Developer',
                icon: PythonSVG,
                alt: 'Python logo',
            },
            {
                title: 'Blockchain Developer',
                icon: BlockchainSVG,
                alt: 'Blockchaing logo',
            },
        ],
        buttonLabel: 'Zobacz roadmapę',
    },
    en: {
        title: '',
        subtitle: '',
        paths: [],
        buttonLabel: '',
    },
};
