import React from "react"
import Camera from "./images/camera.svg"
import Smartphone2 from "./images/smartphone_2.svg"
import Illustration from "./images/illustration.svg"
import Illustration2 from "./images/illustration2.svg"
import {  AnimatedLink } from "@components/shared"

import {
  container,
  container__button,
  container__item,
  container__item__content,
  reverse,
} from "./styles/start.module.scss"

interface Props {}

const Start: React.FC<Props> = (): JSX.Element => {
  const testSrc = 'http://lha.api.localhost-group.com/wp-content/uploads/2021/08/presentation.svg';
  const testSrc2 = 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3?w=161&fit=crop';
  return (
    <section className={container}>
      <h2>Nauka w Akademii krok po kroku</h2>
      {content.pl.options.map((e, i) => {
        return(
        <section
          className={`${container__item} ${i % 2 === 1 ? reverse : ""}`}
          key={i}
        >
          <img src={e.image} alt="" loading="lazy" />
          <div className={container__item__content}>
            <h3>              <strong>{i + 1}. </strong>{e.subtitle}</h3>
            <p>
              {e.text}
            </p>
          </div>
        </section>
        )
      }
      )}
            <AnimatedLink to={"/#pricing"}className={container__button}>Sprawdź naszą ofertę</AnimatedLink>
    </section>
  )
}

export default Start



const content = {
  pl: {
    title: "Jak zacząć się uczyć w Akademii?",
    options: [
      {
        subtitle:"Kontaktujesz się z nami",
        text:
          "Napisz do nas i umów się na wideokonferencję. Każdy uczy się inaczej i my podchodzimy do każdego indywidualnie. Trzeba pogadać, aby się poznać :)",
        image: Illustration
      },
      {
        subtitle:"Rozmawiasz o nauce z mentorem",
        text:
          "Pierwsze mentor zada Ci dużo pytań na wideokonferencji, aby wiedzieć gdzie aktualnie dotarłeś w swoim rozwoju oraz w jaki sposób najlepiej możemy Ci pomóc.",
        image: Smartphone2
      },
      {
        subtitle:"Decydujesz się na naukę",
        text:
          "Po rozmowie masz spokojnie kilka dni na decyzję, czy odpowiadają Ci warunki i forma nauki z mentorem. Dodatkowo możesz zawsze dopytać, jeśli masz jakieś wątpliwości.",
        image: Illustration2
      },
      {
        subtitle:"Rozpoczynasz naukę",
        text:
          "Jeśli decyzja jest już podjęta - opłacasz naukę na najbliższy miesiąc, a potem mentor wysyła Ci zaproszenie na Slacka, które rozpoczyna nowy, lepszy rozdział Twojego rozwoju w kierunku IT!",
        image: Camera
      },
    ],
  },
  en: {
    title: "How to start studying?",
    options: [
      {
        text:
          "Write to us and make an appointment for a video conference, where we will answer all your questions and help you to take the direction and pace of development.",
        image: Illustration,
      },
      {
        text:
          "If you decide to study with us - you pay the tuition fees for the next month and we send you the materials and tasks from the first module. Soon you'll get your pixelart avatar of the academy from our graphic designer 🙂",
        image: Smartphone2,
      },
      {
        text:
          "You learn from a given module in the meantime by doing tasks that allow you to consolidate your new knowledge. If you run into difficulties, you arrange a consultation with a mentor who helps you to get through.",
        image: Illustration2,
      },
      {
        text:
          "After completing the module and checking the tasks, you have a video conference with a mentor who shows you what you did well and what you could have done better.",
        image: Camera,
      },
      {
        text:
          "If the mentor accepts your assignments- you will receive another tuition bill and another module and tasks.",
        image: "http://lha.api.localhost-group.com/wp-content/uploads/2021/08/presentation.svg",
      },
    ],
  },
}
