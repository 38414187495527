import { GitHubApi } from '@api';
import {
    CustomerCtx,
    CartCtx,
    useActions,
    useContextState,
} from '@components/contexted';
import { AnimatedButton } from '@components/shared';
import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import GitHubLogin from 'react-github-login';

import CloseSVG from './icons/close';
import {
    modal__content__control,
    modal__content__coupon__checkbox,
    checkbox,
    checkbox__box,
    checkbox__text,
} from './styles/paymentmodal.module.scss';

export const defaultValues = () => {
    let ctx = null;
    if (typeof window !== 'undefined') {
        ctx = localStorage.getItem('CustomerCtx');
    }
    return ctx
        ? JSON.parse(ctx)
        : {
              first_name: '',
              last_name: '',
              address_1: '',
              address_2: '',
              city: '',
              postcode: '',
              country: '',
              email: '',
              note: 'Testy',
              nip: '',
          };
};

const StepThree = ({ statenip, setStateNip, formik: { errors, touched } }) => {
    const { setVatFee } = useActions(CartCtx, ['setVatFee']);

    const handleClick = (checked) => {
        if (checked) {
            setStateNip(true);
            setVatFee({ type: 'add' });
        } else {
            setStateNip(false);
            setVatFee({ type: 'remove' });
        }
    };

    return (
        <>
            <div className={modal__content__control}>
                <label>Ulica:</label>
                <Field
                    name="address_1"
                    type="text"
                    placeholder="np. Miejska 4"
                />
                {errors.address_1 && touched.address_1 ? (
                    <p>{errors.address_1}</p>
                ) : null}
            </div>
            <div className={modal__content__control}>
                <label>Miasto:</label>
                <Field name="city" type="text" placeholder="np. Warszawa" />
                {errors.city && touched.city ? <p>{errors.city}</p> : null}
            </div>
            <div className={modal__content__control}>
                <label>Kod pocztowy:</label>
                <Field name="postcode" type="text" placeholder="np. 00-001" />
                {errors.postcode && touched.postcode ? (
                    <p>{errors.postcode}</p>
                ) : null}
            </div>
            <div className={modal__content__control}>
                <div className={modal__content__coupon__checkbox}>
                    <label id="checkbox" className={checkbox}>
                        <input
                            onClick={(ev) => handleClick(ev.target.checked)}
                            type="checkbox"
                        />
                        <span className={checkbox__box}>
                            <CloseSVG />
                        </span>
                        <span className={checkbox__text}>
                            Chcę otrzymać fakturę VAT na NIP:
                        </span>
                    </label>
                </div>
                <Field
                    disabled={!statenip}
                    name="nip"
                    type="text"
                    placeholder="np. 106-00-00-062"
                />
                {errors.nip && touched.nip ? <p>{errors.nip}</p> : null}
            </div>
        </>
    );
};

export default StepThree;
