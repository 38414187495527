import { AnimatedSection } from '@components/shared';
import React, {
    forwardRef,
    Ref,
    useRef,
    useCallback,
    useState,
    useContext,
    useEffect,
} from 'react';
import { Pajacyk } from "@components/standard"
import { StandardCard, SelectorCard } from './components';
import Calendar from './images/calendar.svg';
import {
    container,
    container__cards,
    container__info,
    container__info__group,
} from './styles/roadmap.module.scss';

const RoadMapOffer: React.ForwardRefRenderFunction<Ref<HTMLElement>> = (
    props,
    ref,
): JSX.Element => {
    const { products } = props;
    const [state, setState] = useState({
        value: null,
        regularValue: null,
        quantity: 1,
        product_id: null,
        variation_id: null,
        variant: null,
        sale: false,
        price: '',
        regularPrice: '',
        unit: 'PLN',
        name: '',
        slug: '',
        image: null,
        total: 0,
    });
    const mainRoadmaps = products.filter(
        (el) =>
            el.productCategories.nodes.findIndex(
                (node) => node.slug === 'roadmapy-glowne',
            ) !== -1,
    );
    const selectRoadmaps = products.filter(
        (el) =>
            el.productCategories.nodes.findIndex(
                (node) => node.slug === 'roadmapy',
            ) !== -1,
    );
    const solutionsProduct = products.find((el) => el.databaseId === 1784);
    return (
        <section className={container} id="pricing">
            <h2>Wybierz Roadmapę dopasowaną do swojego etapu rozwoju</h2>
            <div className={container__cards}>
                {mainRoadmaps.map((product: Object, i: number) => {
                    return (
                        <StandardCard
                            state={state}
                            setState={setState}
                            product={product}
                            openModal={() => ref.current.toggle()}
                        />
                    );
                })}
                <SelectorCard
                    products={selectRoadmaps}
                    solutionsProduct={solutionsProduct}
                    openModal={() => ref.current.toggle()}
                />
            </div>
            <Pajacyk />
            <div className={container__info}>
                <div className={container__info__group}>
                    <img src={Calendar} alt="clock" />
                    <small>
                        presale - materiały, zadania oraz rozwiązania będą
                        dochodzić co 2 tyg, przez najbliższy czas, aż uznam, że
                        są już wystarczająco wypakowane przydatną treścią
                    </small>
                </div>
            </div>
        </section>
    );
};

export default forwardRef(RoadMapOffer);
