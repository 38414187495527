import React, { useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';

import {
    wrapper__group,
    wrapper__group__external,
    wrapper__group__internal,
    wrapper__group__external_question,
    wrapper__group__external_arrow,
} from '../styles/faq.module.scss';
import AnswerIcon from './icons/answer.svg';
import Arrow from './icons/arrow.svg';
import QuestionIcon from './icons/questionMark.svg';

const Question = ({ el }: { el: { q: string; a: string } }) => {
    const [show, set] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const questionRef = useRef<HTMLDivElement | null>(null);
    const [height, setHeight] = useState(0);
    const [questionHeight, setQuestionHeight] = useState(0);
    const transitions = useTransition(show, null, {
        from: { position: 'absolute', opacity: 0, transform: 'scale(0)' },
        enter: { opacity: 1, transform: 'scale(1)' },
        leave: { opacity: 0, transform: 'scale(0)' },
    });

    useEffect(() => {
        const handleResize = () => {
            setHeight(ref.current?.offsetHeight ?? 0);
            setQuestionHeight(questionRef.current?.offsetHeight ?? 0);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (ref.current) {
            setHeight(ref.current.offsetHeight);
        }
        if (questionRef.current) {
            setQuestionHeight(questionRef.current.offsetHeight);
        }
    }, [show]);

    let cleanDescription = el.a.replace(/<\/?[^>]+(>|$)/g, '');

    const { q } = el;

    return (
        <div
            className={wrapper__group}
            style={{
                paddingBottom: show ? (height ? height + 32 : '32px') : '32px',
            }}
            onClick={() => set((prev) => !prev)}>
            <div
                className={wrapper__group__external}
                style={{ marginBottom: show ? '20px' : '0px' }}
                ref={questionRef}>
                <img
                    src={QuestionIcon}
                    alt="?"
                    className={wrapper__group__external_question}
                />
                <p>{el.q}</p>
                <img
                    src={Arrow}
                    alt="arrow"
                    className={wrapper__group__external_arrow}
                />
            </div>
            <div className={wrapper__group__internal}>
                {transitions.map(
                    ({ item, key, props }) =>
                        item && (
                            <animated.div
                                style={{
                                    ...props,
                                    transformOrigin: 'top left',
                                }}
                                ref={ref}>
                                <img src={AnswerIcon} alt="alt" />
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: el.a,
                                    }}></p>
                            </animated.div>
                        ),
                )}
            </div>
        </div>
    );
};

export default Question;
