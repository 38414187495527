// extracted by mini-css-extract-plugin
export var button = "Q_l";
export var container__content__paths__single__content__option__button = "Q_c4";
export var container = "Q_g";
export var container__header = "Q_b4";
export var container__content = "Q_n";
export var container__content__image = "Q_p";
export var container__content__image__charity = "Q_b5";
export var container__content__paths = "Q_b6";
export var container__content__paths__single = "Q_b7";
export var container__content__paths__single__content = "Q_c5";
export var container__content__paths__single__content__option = "Q_c6";
export var container__content__paths__single__content__option__headers = "Q_c7";