import { useActions, CartCtx, useContextState } from '@components/contexted';
import { AnimatedButton } from '@components/shared';
import React, { useState, useContext, useRef } from 'react';
import { useEffect } from 'react';

import { SingleAttribute } from './StandardCard';
import {
    card,
    card__price,
    card__attributes,
    card__divider,
    card__checkbox,
    card__buttons__primary,
} from './styles/card.module.scss';

const cleanPrice = (price) => {
    return parseFloat(price.replace(/[$,]+/g, ''));
};

const prepareProduct = (product) => {
    return {
        value: cleanPrice(product.regularPrice),
        product_id: product.databaseId,
        name: product.name,
        slug: product.slug,
        price: cleanPrice(product.regularPrice),
        regularPrice: cleanPrice(product.regularPrice),
        regularValue: cleanPrice(product.regularPrice),
        value: cleanPrice(product.regularPrice),
        quantity: 1,
        image: product.image,
        total: cleanPrice(product.regularPrice),
    };
};
const SelectorCard = (props) => {
    const { products, solutionsProduct, openModal } = props;
    const { dispatch } = useContext(CartCtx);
    const { coupon, items } = useContextState(CartCtx, ['coupon', 'items']);
    const { setCoupon } = useActions(CartCtx, ['setCoupon']);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [checked, setChecked] = useState(true);
    const [state, setState] = useState({
        value: null,
        regularValue: null,
        quantity: 1,
        product_id: null,
        variation_id: null,
        variant: null,
        sale: false,
        price: '',
        regularPrice: '',
        unit: 'PLN',
        name: '',
        slug: '',
        image: null,
        total: 0,
    });

    let fundamentals = products.find((el) => el.databaseId === 1740)
    const fundamentalsVat = fundamentals.attributes.nodes.filter((el) => el.name === 'vat')[0].options[0]

    useEffect(() => {
        setState({
            value: cleanPrice(fundamentals.regularPrice),
            product_id: fundamentals.databaseId,
            name: fundamentals.name,
            slug: fundamentals.slug,
            price: cleanPrice(fundamentals.regularPrice),
            regularPrice: cleanPrice(fundamentals.regularPrice),
            regularValue: cleanPrice(fundamentals.regularPrice),
            value: cleanPrice(fundamentals.regularPrice),
            quantity: 1,
            image: fundamentals.image,
            total: cleanPrice(fundamentals.regularPrice),
            vat: parseFloat(fundamentalsVat),
        })
    },[])

    const changeOption = (product: Object) => {
        setCoupon('');
        setState({
            value: cleanPrice(product.regularPrice),
            product_id: product.databaseId,
            name: product.name,
            slug: product.slug,
            price: cleanPrice(product.regularPrice),
            regularPrice: cleanPrice(product.regularPrice),
            regularValue: cleanPrice(product.regularPrice),
            value: cleanPrice(product.regularPrice),
            quantity: 1,
            image: product.image,
            total: cleanPrice(product.regularPrice),
            vat: parseFloat(product.attributes.nodes.filter((el) => el.name === 'vat')[0].options[0]),
        });
        dispatch({
            type: 'resetCart',
        });
    };
    const solutinsPrepared = prepareProduct(solutionsProduct);
    let rev = [].concat(products).reverse();
    const handleChecked = () => {
        if (checked) {
            dispatch({
                type: 'addToCart',
                payload: solutinsPrepared,
            });
        } else {
            dispatch({
                type: 'removeFromCart',
                payload: solutinsPrepared,
            });
        }
    };

    return (
        <div className={card}>
            <div className={card__price}>
                <text>{checked ? state.price + 10 : state.price} </text>
                <h5> &nbsp;PLN</h5>
            </div>
            <h4>Jedna wybrana roadmapa</h4>
            <select
                onChange={(ev) => {
                    changeOption(JSON.parse(ev.target.value));
                }}>
                {rev.map((el, i) => {
                    const isPresale =
                        el.productCategories.nodes.findIndex(
                            (node) => node.slug === 'presale',
                        ) !== -1;

                    return (
                        <option key={i} value={JSON.stringify(el)}>
                            {isPresale && '(presale) '}
                            {el.name}
                        </option>
                    );
                })}
            </select>
            <label
                htmlFor="checbox"
                className={card__checkbox}
                onClick={() => setChecked((prev) => !prev)}>
                <input type="checkbox" ref={inputRef} checked={checked} />
                Kliknij, jeśli nie chcesz rozwiązań (-10 zł)
            </label>
            <div className={card__divider}></div>
            <p style={{ margin: '10px 0' }}>Każda roadmapa zawiera:</p>
            <div className={card__attributes}>
                <SingleAttribute text="Ścieżkę rozwoju prosto z Akademii Localhost" />
                <SingleAttribute text="Wartościowe materiały sprawdzone przez mentora" />
                <SingleAttribute text="Ogrom zaawansowanych i średniozaawansowanych zadań praktycznych" />
                {checked && (
                    <SingleAttribute text="2 różne rozwiązania dla każdego zadania" />
                )}
                <AnimatedButton
                    className={card__buttons__primary}
                    onClick={() => {
                        if (state.name) {
                            dispatch({
                                type: 'resetCart',
                            });
                            dispatch({
                                type: 'addToCart',
                                payload: state,
                            });
                            handleChecked();
                            openModal();
                        }
                    }}>
                    <a>Wybieram tę!</a>
                </AnimatedButton>
            </div>
        </div>
    );
};

export default SelectorCard;
