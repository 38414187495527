import { AnimatedButton } from '@components/shared';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import React from 'react';

import Form from './Form';
import {
    container,
    container__content,
    container__content__avatars,
    container__content__header,
    container__content__subtitle,
    container__content__footer,
    container__content__footer_button,
} from './styles/unavailable.module.scss';

interface IElement {
    avatar: {
        sourceUrl: string;
    };
}

const Unavailable: React.FC = (): JSX.Element => {
    const { WP } = useStaticQuery(graphql`
        query {
            WP {
                page(id: "cG9zdDoxNg==") {
                    ACF_Counter {
                        counter {
                            avatar {
                                sourceUrl
                            }
                        }
                    }
                }
            }
        }
    `);

    const { counter } = WP.page.ACF_Counter;

    return (
        <div className={container}>
            <div className={container__content}>
                <p className={container__content__header}>
                    Aktualnie nie ma miejsc w Akademii, wszyscy mentorzy są
                    obłożeni na 100%
                </p>
                <div className={container__content__avatars}>
                    {counter.map((el: IElement) => (
                        <img src={el.avatar.sourceUrl} />
                    ))}
                </div>
                <p className={container__content__subtitle}>
                    Jeśli w najbliższym czasie chcesz dołączyć do Akademii to
                    zostaw nam maila w formularzu, a potem wypełnij ankietę,
                    która powie nam do którego mentora pasujesz, a zostaniesz
                    poinformowany TEGO SAMEGO DNIA jeśli jakiś student Akademii
                    znajdzie pracę i u mentora zwolni się miejsce.
                </p>
                <Form />
                <hr />
                <p className={container__content__footer}>
                    Jeśli chcesz przyspieszyć swoją naukę i zacząć uczyć się
                    samodzielnie, możesz zainwestować w nasz aktualny program
                    nauczania. Jak dołączysz do Akademii, samodzielnie
                    rozwiązane zadania zostaną sprawdzone i ocenione.
                </p>
                <AnimatedButton
                    className={container__content__footer_button}
                    onClick={() => navigate('/roadmaps')}>
                    Zobacz roadmapy
                </AnimatedButton>
            </div>
        </div>
    );
};

export default Unavailable;
