import { GitHubApi } from '@api';
import {
    CustomerCtx,
    useActions,
    CartCtx,
    AppCtx,
    useContextState,
} from '@components/contexted';
import { AnimatedButton, Modal } from '@components/shared';
import { Formik, Form } from 'formik';
import { Field } from 'formik';
import React, {
    forwardRef,
    ForwardRefRenderFunction,
    Ref,
    useContext,
    useEffect,
    useState,
} from 'react';
import GitHubLogin from 'react-github-login';
import * as Yup from 'yup';

import { createOrder, initPayment } from '../../ecommerce/Checkout/common';
import { Discount } from '../Home/common';
import StepThree, { defaultValues } from './ContactForm';
import Loader from './Loader';
import StepTwo from './StepTwo';
import {
    modal__content,
    modal__content__pay,
    modal__content__pay__disabled,
    modal__content__control,
    modal__content__github,
} from './styles/paymentmodal.module.scss';

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Imię jest wymagane'),
    last_name: Yup.string().required('Nazwisko jest wymagane'),
    address_1: Yup.string().required('Adres jest wymagany'),
    city: Yup.string().required('Miasto jest wymagane'),
    postcode: Yup.string()
        .matches(/^[0-9]{2}-[0-9]{3}$/, {
            message: 'Kod pocztowy jest nieprawidłowy',
        })
        .required('Kod pocztowy jest wymagany'),
    email: Yup.string()
        .email('Nieprawidłowy email')
        .required('Email jest wymagany'),
    nip: Yup.string().matches(
        /^(([0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2})|([0-9]{3}-[0-9]{2}-[0-9]{2}-[0-9]{3}))$/,
        {
            message: 'Nip jest nieprawidłowy (prawidłowy Nip to np. 106-00-00-062)',
        },
    ),
});

const onSuccessGithub =
    (setFieldValue, changeValue, increaseStep) => (data) => {
        GitHubApi.exchangeCode(data.code).then((data) => {
            if (data) {
                increaseStep();
                setFieldValue('note', data.login);
                changeValue({
                    note: data.login,
                });
            }
        });
    };

const PaymentModal: ForwardRefRenderFunction<Ref<HTMLElement>> = (
    props,
    ref,
): JSX.Element => {
    const cart = useContext(CartCtx);
    const { items } = useContextState(CartCtx, ['items']);
    const { note } = useContextState(CustomerCtx, ['note']);
    const { changeValue } = useActions(CustomerCtx, ['changeValue']);
    const { loading, loaded } = useActions(AppCtx, ['loading', 'loaded']);
    const { setOrderFromApi, onOrderApiError } = useActions(CartCtx, [
        'setOrderFromApi',
        'onOrderApiError',
    ]);
    const { imBusy } = useContextState(AppCtx, 'imBusy');
    const [step, setStep] = useState(0);
    const [statenip, setStateNip] = useState(false);
    useEffect(() => {
        setStep(note.length !== 0 ? 1 : 0);
    }, [note]);

    const onFailGithub = (data) => {
        console.log('onFailGithub', data);
    };

    return (
        <Modal ref={ref} title={false}>
            <Formik
                initialValues={defaultValues()}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    changeValue({ ...values });
                    console.log('v', values);
                    if (!values.note) {
                        return;
                    }

                    if (!cart.state.orderInApi.id) {
                        createOrder({
                            cart: cart.state,
                            customer: values,
                            initLoading: loading,
                        })
                            .then((data) => {
                                loaded();
                                setOrderFromApi(data);
                            })
                            .catch(({ data }) => {
                                loaded();
                                onOrderApiError(data);
                            });
                    } else {
                        initPayment({ cart, initLoading: loading })
                            .then((payment) => {
                                loaded();
                                window.location.href = payment.redirectUri;
                            })
                            .catch((payment) => {
                                loaded();
                                console.log('err', payment);
                            });
                    }
                }}>
                {(formik) => (
                    <Form className={modal__content}>
                        {step === 0 && formik.values.note === '' && (
                            <React.Fragment>
                                <p style={{ fontWeight: 600 }}>
                                    Czy jesteś programistą?
                                </p>

                                <label style={{ textAlign: 'center' }}>
                                    Zaloguj się do Githuba, aby potwierdzić że
                                    jesteś programistą
                                </label>
                                <div>
                                    <GitHubLogin
                                        clientId="c9d3c978b0dccad06dd6"
                                        onSuccess={onSuccessGithub(
                                            formik.setFieldValue,
                                            changeValue,
                                            () => setStep(1),
                                        )}
                                        onFailure={onFailGithub}
                                        buttonText={
                                            <AnimatedButton
                                                className={
                                                    modal__content__github
                                                }>
                                                <h5>Zaloguj się</h5>
                                                <i className="fab fa-github"></i>
                                            </AnimatedButton>
                                        }
                                        valid={true}
                                        redirectUri="https://academy.localhost-group.com/"
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        {step === 1 && (
                            <>
                                <div className={modal__content__control}>
                                    <label>Nick na Githubie</label>
                                    <Field
                                        name="note"
                                        type="text"
                                        disabled={true}
                                    />
                                </div>
                                <StepTwo formik={formik} />
                            </>
                        )}
                        {step === 2 && <StepThree formik={formik} statenip={statenip} setStateNip={setStateNip} />}
                        <Discount items={items} />
                        {step === 1 && (
                            <AnimatedButton
                                className={`${modal__content__pay}`}
                                onClick={() => setStep(2)}>
                                <a>Dane adresowe</a>
                            </AnimatedButton>
                        )}
                        {step === 2 && (
                            <div style={{ display: 'flex' }}>
                                <AnimatedButton
                                    className={modal__content__pay}
                                    onClick={() => setStep(1)}
                                    style={{ marginRight: '20px' }}>
                                    <a>Powrót</a>
                                </AnimatedButton>
                                <AnimatedButton
                                    submit="true"
                                    className={
                                        imBusy
                                            ? modal__content__pay__disabled
                                            : modal__content__pay
                                    }>
                                    <a>
                                        {imBusy ? (
                                            <Loader />
                                        ) : cart.state.orderInApi.id ? (
                                            <>Rozpocznij płatność</>
                                        ) : (
                                            <>Potwierdź zamówienie</>
                                        )}
                                    </a>
                                </AnimatedButton>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default forwardRef(PaymentModal);
