import { WPApi } from '@api';
import { AnimatedButton } from '@components/shared';
import { Form as FormikForm, Field, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';

import Loader from './Loader';
import {
    container__content__contact__form,
    container__content__contact__form__levels,
    container__content__contact__form__levels__single,
    container__content__contact__button,
} from './styles/form.module.scss';

const defaultState = {
    // subject: "",
    email: '',
    message: '',
    level: '',
};

const levels = [
    'Dopiero zaczynam (Zielony)',
    'Już coś umiem (Newbie)',
    'Myślę, że jestem gotowy na rynek (PreJunior)',
    'Pracuję jako Junior',
    'Pracuję jako Regular',
];

const ValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Email nie jest prawidłowy')
        .required('Pole email jest wymagane'),
    level: yup.string().required('Proszę wybrać poziom'),
    // subject: yup.string().required("Wybierz powód"),
});
const Form: React.FC = (): JSX.Element => {
    const [message, setMessage] = useState({
        message: '',
        err: false,
        isLoading: false,
    });
    return (
        <Formik
            initialValues={defaultState}
            validationSchema={ValidationSchema}
            onSubmit={(values, { resetForm }) => {
                if (message.isLoading) {
                    return;
                }

                setMessage((prev) => ({
                    ...prev,
                    isLoading: true,
                    message: '',
                    err: false,
                }));
                WPApi.sendMessageToUnavailable(values.email, values.level).then(
                    (data) => {
                        setMessage((prev) => ({
                            ...prev,
                            isLoading: false,
                            message: data.message,
                        }));
                    },
                );
                resetForm();
            }}>
            {({ errors, touched, isSubmitting }) => (
                <FormikForm className={container__content__contact__form}>
                    <label htmlFor="email">Twój adres email:</label>
                    <Field
                        type="email"
                        name="email"
                        placeholder="jan@kowalski.pl"
                    />
                    {errors.email && touched.email && (
                        <small>{errors.email}</small>
                    )}

                    <div className={container__content__contact__form__levels}>
                        <label>Twój poziom:</label>
                        {levels.map((el, i) => (
                            <div
                                className={
                                    container__content__contact__form__levels__single
                                }>
                                <label>
                                    <Field
                                        key={i}
                                        type="radio"
                                        name="level"
                                        value={el}
                                    />
                                    {el}
                                </label>
                            </div>
                        ))}
                    </div>

                    {errors.level && touched.level && (
                        <small>{errors.level}</small>
                    )}
                    <AnimatedButton
                        className={container__content__contact__button}
                        submit="true"
                        style={{
                            pointerEvents: message.isLoading ? 'none' : 'all',
                        }}>
                        {message.isLoading ? (
                            <Loader />
                        ) : (
                            'Umawiam się na konsultację'
                        )}
                    </AnimatedButton>
                    {message.message && (
                        <p
                            style={{
                                padding: '10px 0',
                                maxWidth: '400px',
                                textAlign: 'center',
                            }}>
                            {message.message}
                        </p>
                    )}
                </FormikForm>
            )}
        </Formik>
    );
};

export default Form;
