import { ResizedImage } from '@components/shared';
import { motion } from 'framer-motion';
import { navigate } from 'gatsby';
import React from 'react';
import { useEffect } from 'react';
import Slider from 'react-slick';

import useScrollHeight from '../../layout/Header/useScrollHeight';
import ArrowIcon from './icons/arrow';
import {
    container,
    container__slider,
    container__slider__slide,
    prev_arrow,
    next_arrow,
    smaller,
} from './styles/blogslider.module.scss';

interface IBlogSlider {
    posts: {
        nodes: [
            {
                id: number;
                title: string;
                slug: string;
                featuredImage: any;
            },
        ];
    };
}

const PrevArrow = (props) => {
    const { onClick } = props;
    return <ArrowIcon className={prev_arrow} onClick={onClick} />;
};

const NextArrow = (props) => {
    const { onClick } = props;
    return <ArrowIcon className={next_arrow} onClick={onClick} />;
};

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slideToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    ccsEase: 'linear',
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

const BlogSlider: React.FC<IBlogSlider> = ({ posts }): JSX.Element => {
    const { height } = useScrollHeight();

    return (
        <React.Fragment>
            <motion.div
                animate={{ top: height > 500 ? 80 : 30 }}
                transition={{ delay: 1 }}
                className={`${container} ${height > 50 && smaller}`}>
                <Slider {...settings} className={container__slider}>
                    {posts.nodes.map((post) => (
                        <div
                            key={post.id}
                            className={container__slider__slide}
                            onClick={() => navigate(`/blog/${post.slug}`)}>
                            <h3>{post.title}</h3>
                        </div>
                    ))}
                </Slider>
            </motion.div>
        </React.Fragment>
    );
};

export default BlogSlider;
