// extracted by mini-css-extract-plugin
export var button = "z_l";
export var modal__content = "z_Q";
export var modal__content__info = "z_b8";
export var modal__content__payu = "z_b9";
export var modal__content__pay = "z_cb";
export var modal__content__pay__disabled = "z_cc";
export var modal__content__github = "z_cd";
export var modal__content__coupon = "z_cf";
export var modal__content__control = "z_cg";
export var modal__content__coupon__checkbox = "z_ch";
export var checkbox = "z_cj";
export var checkbox__box = "z_ck";
export var checkbox__text = "z_cl";